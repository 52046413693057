<template>
  <div class="foot">
    <van-tabbar route style="height: 68px; background-color: #f3f6ff">
      <van-tabbar-item icon="home-o" replace to="/index">
        首页
        <template #icon="props">
          <img
            style="height: 34px"
            :src="props.active ? icon.homeicon.active : icon.homeicon.inactive"
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="point-gift-o" replace to="/activity">
        优惠
        <template #icon="props">
          <img
            style="height: 34px"
            :src="
              props.active
                ? icon.activityicon.active
                : icon.activityicon.inactive
            "
          />
        </template>
      </van-tabbar-item>
      <!-- <van-tabbar-item icon="service-o" replace to="/kefu">
        客服
        <template #icon="props">
          <img
            style="height: 34px"
            :src="props.active ? icon.kefuicon.active : icon.kefuicon.inactive"
          />
        </template>
      </van-tabbar-item> -->
      <van-tabbar-item icon="balance-o" replace to="/zhanzhu">
        赞助
        <template #icon="props">
          <img
            style="height: 34px"
            :src="
              props.active ? icon.zhanzhuicon.active : icon.zhanzhuicon.inactive
            "
          />
        </template>
      </van-tabbar-item>
      <van-tabbar-item icon="contact-o" replace to="/mine">
        我的
        <template #icon="props">
          <img
            style="height: 34px"
            :src="props.active ? icon.mineicon.active : icon.mineicon.inactive"
          />
        </template>
      </van-tabbar-item>
    </van-tabbar>
  </div>
</template>
<script>
export default {
  data() {
    return {
      icon: {
        homeicon: {
          active: "/static/icon/home_check.png",
          inactive: "/static/icon/home.png",
        },
        activityicon: {
          active: "/static/icon/activity_check.png",
          inactive: "/static/icon/activity.png",
        },
        kefuicon: {
          active: "/static/icon/kefu_check.png",
          inactive: "/static/icon/kefu.png",
        },
        zhanzhuicon: {
          active: "/static/icon/zhanzhu_check.png",
          inactive: "/static/icon/zhanzhu.png",
        },
        mineicon: {
          active: "/static/icon/mine_check.png",
          inactive: "/static/icon/mine.png",
        },
      },
    };
  },
  methods: {
    change() {
      console.log(this.active, "active");
      if (this.active == 0) {
        this.$parent.goNav("/index");
      } else if (this.active == 1) {
        this.$parent.goNav("/activity");
      } else if (this.active == 2) {
        this.$parent.goNav("/kefu");
      } else if (this.active == 3) {
        this.$parent.goNav("/zhanzhu");
      } else if (this.active == 4) {
        this.$parent.goNav("/mine");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.foot {
  width: 100%;
  height: 50rpx;
  border: 1rpx solid red;
  /* position: relative;
  bottom: 0; */
}
.cont {
  width: 100%;
  height: 50rpx;
  border: 1rpx solid red;
}
.van-tabbar-item--active {
  color: #1989fa;
  background-color: #f3f6ff !important;
}
</style>
