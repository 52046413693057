<template>
  <div style="width: 100%; min-height: 100vh; background: rgb(237, 241, 255)">
    <van-nav-bar
      style="
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        background-color: #edf2fd;
      "
      title="赞助详情"
      left-arrow
      @click-left="$router.back()"
    />
    <div class="cont">
      <iframe
        style="height: 100%; width: 100%"
        :src="url"
        frameborder="0"
      ></iframe>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: 0,
      url: "",
      url_1: "https://sponsor.tianhe333.com/1.html",
      url_2: "https://sponsor.tianhe333.com/2.html",
    };
  },
  created() {
    let query = this.$route.query;
    this.id = query.id;
    if (this.id == 1) {
      this.url = this.url_1;
    } else if (this.id == 2) {
      this.url = this.url_2;
    }
  },
  methods: {},
  mounted() {},
  updated() {},
};
</script>

<style lang="scss" scoped>
.cont {
  width: 100%;
  height: 100vh;
  margin-top: 47px;
  padding: 10px;
}
</style>
