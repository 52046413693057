<template>
  <div class="foot">
    <div class="van-nav-bar__content">
      <van-nav-bar
        style="
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          background-color: #edf2fd;
        "
        title="合营计划"
        left-arrow
        @click-left="$router.back()"
      />
    </div>
    <div data-v-e691a368="" class="pahsn">
      <img
        data-v-e691a368=""
        data-v-56fcd294=""
        src="/static/image/__al__title01.png"
        style="display: block; width: 80%; margin: 0px auto; padding-top: 30px"
      />
      <img
        data-v-e691a368=""
        data-v-56fcd294=""
        src="/static/image/__al__person.png"
        style="display: block; width: 100%; margin: 0px auto"
      />
      <!-- 联系客服 -->
      <!-- <div data-v-e691a368="" class="zixun">
        <img
          data-v-e691a368=""
          data-v-56fcd294=""
          src="/static/image/16044962635685155.png"
        />
        <div data-v-e691a368="" class="cnets">
          <div data-v-e691a368="" class="tos">合营部</div>
          <div data-v-e691a368="" class="bos" @click="$parent.goNav('/kefu')">立即咨询</div>
        </div>
        <div data-v-e691a368="" class="zusnb" @click="$parent.goNav('/kefu')">咨询</div>
      </div> -->
      <div data-v-e691a368="" class="bsd">
        <form data-v-e691a368="" class="van-form">
          <div
            data-v-e691a368=""
            class="van-cell van-field van-field--disabled"
          >
            <div class="van-cell__title van-field__label">
              <span>用户名</span>
            </div>
            <div class="van-cell__value van-field__value">
              <div class="van-field__body">
                <input
                  type="text"
                  disabled="disabled"
                  class="van-field__control"
                  :value="name"
                />
              </div>
            </div>
          </div>
          <div
            data-v-e691a368=""
            class="van-cell van-field van-field--disabled"
          >
            <div class="van-cell__title van-field__label">
              <span>真实姓名</span>
            </div>
            <div class="van-cell__value van-field__value">
              <div class="van-field__body">
                <input
                  type="text"
                  disabled="disabled"
                  class="van-field__control"
                  :value="realname"
                />
              </div>
            </div>
          </div>
          <div data-v-e691a368="" class="van-cell van-field">
            <div class="van-cell__title van-field__label">
              <span>联系方式</span>
            </div>
            <div class="van-cell__value van-field__value">
              <div class="van-field__body">
                <input
                  type="text"
                  placeholder="请输入您的联系方式"
                  class="van-field__control"
                  v-model="mobile"
                />
              </div>
            </div>
          </div>
          <div data-v-e691a368="" class="van-cell van-field">
            <div class="van-cell__title van-field__label">
              <span>申请理由</span>
            </div>
            <div class="van-cell__value van-field__value">
              <div class="van-field__body">
                <input
                  type="text"
                  placeholder="请输入申请说明"
                  class="van-field__control"
                  v-model="apply_info"
                />
              </div>
            </div>
          </div>
        </form>
        <button
          data-v-e691a368=""
          class="van-button van-button--info van-button--normal van-button--block"
          @click="applyagent"
        >
          <div data-v-e691a368="" class="van-button__content">
            <span data-v-e691a368="" class="van-button__text">加入我们</span>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      name: "",
      realname: "",
      mobile: "",
      apply_info: "",
    };
  },
  created() {
    this.getuser();
  },
  methods: {
    // 获取用户信息
    async getuser() {
      let res = await this.$apiFun.post("/api/user");
      this.name = res.data.username;
      this.realname = res.data.realname;
    },
    // 提交申请
    async applyagent() {
      console.log(this.mobile, "this.mobile");
      let regExp =
        /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/;
      if (!regExp.test(this.mobile)) {
        this.$parent.showTost(0, "请输入正确手机号");
        return;
      }
      if (!this.apply_info) {
        this.$parent.showTost(0, "请输申请理由");
        return;
      }
      let res = await this.$apiFun.post("/api/applyagentdo", {
        apply_info: this.apply_info,
        mobile: this.mobile,
      });
      if (res.code == 200) {
        this.$toast.success(res.message);
      } else {
        this.$toast.fail(res.message);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  font-size: 12px;
}
.pahsn {
  background: url("/static/image/__al__background.png") no-repeat;
  background-size: 100% 100%;
  min-height: 95vh;
  width: 100%;
}
.zixun {
  width: 88%;
  background: #fdfdfd;
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px;
  position: relative;
  margin: -96px auto 0;
  opacity: 0.9;
}
.zixun img {
  width: 30px;
  margin-right: 10px;
}
.zixun .cnets {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-left: 1px solid #ccc;
  padding-left: 10px;
}
.zixun .cnets .tos {
  font-size: 12px;
  color: #999;
}
.zixun .cnets .bos {
  font-size: 14px;
  color: #1e1e1e;
}
.zixun .zusnb {
  width: 60px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  font-size: 12px;
  color: #fff;
  background-color: #597ef7;
  border-radius: 4px;
}
.bsd {
  width: 90%;
  background: #fdfdfd;
  border-radius: 10px;
  padding: 20px 10px;
  margin: 10px auto 0;
}
.van-button--block {
  display: block;
  width: 100%;
}
.van-button--normal {
  padding: 0 15px;
  font-size: 14px;
}
.van-button--info {
  color: #fff;
  background-color: #1989fa;
  border: 1px solid #1989fa;
}
.van-button {
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  height: 44px;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.2;
  text-align: center;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: opacity 0.2s;
  transition: opacity 0.2s;
  -webkit-appearance: none;
}
</style>
