<template>
  <div class="foot">
    <div class="pageTop">赞助</div>
    <div class="hei"></div>

    <div class="lis" @click="opurl(1)">
      <div class="lfs">
        <img data-v-9ff44138="" src="/static/image/jl.png" />
        <div class="you">尤文图斯</div>
        <div class="te">官方区域合作伙伴</div>
      </div>
      <div class="rig">
        <img data-v-9ff44138="" src="/static/image/jl_1.png" alt="" />
      </div>
    </div>
    <div class="lis" @click="opurl(2)">
      <div class="lfs">
        <img data-v-9ff44138="" src="/static/image/jl.png" />
        <div class="you">阿斯顿维拉</div>
        <div class="te">官方全球顶级合作伙伴</div>
      </div>
      <div class="rig">
        <img data-v-9ff44138="" src="/static/image/jl_2.png" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
    };
  },
  created() {
    this.geturl();
  },
  methods: {
    // 获取地址
    async geturl() {
      let res = await this.$apiFun.get("/api/getVisitUrl");
      console.log(res, "res");
    },
    opurl(id) {
      this.$parent.goNav(`/zhanzhuye?id=${id}`);
    },
  },
};
</script>
<style lang="scss" scoped>
.hei {
  width: 100%;
  height: 80px;
  // border: 1px solid red;
}
.lis {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 40px);
  margin: 20px auto 0;
  border-radius: 20px;
  background-color: #f8f9ff;
  -webkit-box-shadow: 0 0.04rem 0.2rem rgba(199, 212, 255, 0.42);
  box-shadow: 0 0.04rem 0.2rem rgba(199, 212, 255, 0.42);
  border: 0.02rem solid #fff;
}
.lis .lfs {
  height: 100%;
  width: 45%;
  text-align: center;
  color: #98a8c5;
}
.lis .lfs img {
  width: 30px;
  display: block;
  margin: 20px auto 0;
}
.lis .lfs .you {
  font-weight: 700;
  font-size: 18px;
  margin-top: 10px;
}
.lis .lfs .te {
  font-size: 12px;
  margin-top: 5px;
}
.lis .rig {
  width: 55%;
  font-size: 0;
}
.lis .rig img {
  width: 100%;
}
</style>
