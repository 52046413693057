<template>
  <div id="__next" style="overflow-y: auto">
    <div class="entry_entryStyle__FXwIQ">
      <div
        class="entry_entryMain__2anz2 entry_loginAnimated__1Gwda"
        v-if="infoType == 0"
      >
        <div
          style="
            width: 100vw;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        >
          <div class="login_formContainer__2zhUW">
            <div class="login_normalContent__1I3Xg">
              <div class="login_title__1VIs3">登录</div>
              <div class="login_form__1BQh1">
                <div class="login_normalLoginWrap__Q4P0O">
                  <div class="login_inputBox__3j84z">
                    <div class="login_inputGroup__1xPH8">
                      <div class="login_accountIcon__1eker"></div>
                      <div class="login_inputMain__18b4V">
                        <input
                          maxlength="32"
                          v-model="loginInfo.name"
                          placeholder="用户名"
                          class="login_input__hMBpm"
                          type="text"
                          autocomplete="off"
                        />
                      </div>
                      <div
                        class="login_operateBtn__25zoY login_deleteBtn__Tamqm"
                        style="opacity: 0"
                      ></div>
                    </div>
                    <div class="login_inputGroup__1xPH8">
                      <div class="login_passwordIcon__foPzR"></div>
                      <div class="login_inputMain__18b4V">
                        <input
                          placeholder="密码"
                          v-model="loginInfo.password"
                          maxlength="32"
                          class="login_input__hMBpm"
                          type="password"
                          autocomplete="off"
                        />
                      </div>
                      <div
                        class="login_operateBtn__25zoY login_hidePwdBtn__3EBnN"
                        style="opacity: 0"
                      ></div>
                    </div>
                    <!-- 验证码 -->
                    <!-- <div class="login_inputGroup__1xPH8">
                      <div class="login_passwordIcon__foPzR"></div>
                      <div
                        class="login_inputMain__18b4V"
                        style="flex-flow: row nowrap; align-items: center"
                      >
                        <input
                          maxlength="6"
                          v-model="loginInfo.code"
                          placeholder="验证码 "
                          class="login_input__hMBpm"
                          type="text"
                          autocomplete="off"
                        />
                        <img
                          style="cursor: pointer; height: 30px; width: 70px"
                          :src="`/static/image/yzm/${imgLis[index]}.png`"
                          @click="changIndex"
                          alt=""
                        />
                      </div>
                      <div
                        class="login_operateBtn__25zoY login_deleteBtn__Tamqm"
                        style="opacity: 0"
                      ></div>
                    </div> -->
                  </div>

                  <div class="login_btnGroup__37-ja">
                    <button
                      @click="login"
                      class="login_activeBtn__3wLDx"
                      type="button"
                      data-analytics="button"
                      data-label="登录"
                      data-key="yb_lgn_btn_tap"
                    >
                      登录
                    </button>
                  </div>
                  <div class="login_visitorBtn__2xo1h">
                    <a
                      data-analytics="button"
                      @click="$parent.goNav('/')"
                      data-label="先去逛逛"
                      data-key="yb_tour"
                      >先去逛逛</a
                    >
                    <!-- <a @click="$parent.openKefu">在线客服</a> -->
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="login_locationToRight__gIQoN" @click="changInfoType(1)">
              <div class="login_locationContent__o3pkw">
                <div class="login_linkRegisterIcon__3AVOn"></div>
                <div class="login_text__-W8Ok">
                  <span>注</span><span>册</span><span>新</span><span>用</span
                  ><span>户</span>
                </div>
              </div>
            </div> -->
          </div>
        </div>
      </div>
      <div
        v-if="infoType == 1"
        class="entry_entryMain__2anz2 entry_registerAnimated__2Bdsz entry_transition__38Yv4"
      >
        <div
          style="
            width: 100vw;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
        >
          <div class="register_formContainer__3DhlE">
            <div
              class="register_locationToLeft__17bW-"
              @click="changInfoType(0)"
            >
              <div class="register_locationContent__2IbiZ">
                <div class="register_linkRegisterIcon__bUBqC"></div>
                <div class="register_text__33mcY">
                  <span>返</span><span>回</span><span>登</span><span>录</span>
                </div>
              </div>
            </div>
            <div class="register_registerContent__16A8K">
              <div class="register_registerTitle__gkT0e">注册</div>
              <div class="register_form__1pmZ3">
                <div class="register_inputBox__6n6TQ">
                  <div class="register_inputGroup__2VkRD">
                    <div
                      class="register_inputIcon__2arpa register_accountIcon__27e07"
                    ></div>
                    <div class="register_inputMain__3Ur5m">
                      <input
                        v-model="registerInfo.name"
                        placeholder="用户名"
                        maxlength="32"
                        data-inputtype="register"
                        class="register_input__1ukwi"
                        type="text"
                      />
                    </div>
                    <div
                      class="register_operateBtn__12YnF register_deleteBtn__2EnTW"
                      style="opacity: 0"
                    ></div>
                  </div>

                  <div class="register_inputGroup__2VkRD">
                    <div
                      class="register_inputIcon__2arpa register_passwordIcon__18fIi"
                    ></div>
                    <div class="register_inputMain__3Ur5m">
                      <input
                        placeholder="登录密码"
                        v-model="registerInfo.password"
                        maxlength="32"
                        data-inputtype="register"
                        class="register_input__1ukwi"
                        type="password"
                        autocomplete="off"
                      />
                    </div>
                    <div
                      class="register_operateBtn__12YnF register_hidePwdBtn__Qkz7h"
                      style="opacity: 0"
                    ></div>
                  </div>
                  <div class="register_inputGroup__2VkRD">
                    <div
                      class="register_inputIcon__2arpa register_passwordIcon__18fIi"
                    ></div>
                    <div class="register_inputMain__3Ur5m">
                      <input
                        placeholder="确认密码"
                        v-model="registerInfo.confirmPass"
                        data-inputtype="register"
                        maxlength="32"
                        class="register_input__1ukwi"
                        type="password"
                        autocomplete="off"
                      />
                    </div>
                    <div
                      class="register_operateBtn__12YnF register_hidePwdBtn__Qkz7h"
                      style="opacity: 0"
                    ></div>
                  </div>
                  <div class="register_inputGroup__2VkRD">
                    <div
                      class="register_inputIcon__2arpa register_accountIcon__27e07"
                    ></div>
                    <div class="register_inputMain__3Ur5m">
                      <input
                        v-model="registerInfo.realname"
                        placeholder="真实姓名"
                        maxlength="32"
                        data-inputtype="register"
                        class="register_input__1ukwi"
                        type="text"
                      />
                    </div>
                    <div
                      class="register_operateBtn__12YnF register_deleteBtn__2EnTW"
                      style="opacity: 0"
                    ></div>
                  </div>
                  <div class="register_inputGroup__2VkRD">
                    <div
                      class="register_inputIcon__2arpa register_passwordIcon__18fIi"
                    ></div>
                    <div class="register_inputMain__3Ur5m">
                      <input
                        placeholder="支付密码"
                        v-model="registerInfo.paypassword"
                        maxlength="32"
                        data-inputtype="register"
                        class="register_input__1ukwi"
                        type="password"
                        autocomplete="off"
                      />
                    </div>
                    <div
                      class="register_operateBtn__12YnF register_hidePwdBtn__Qkz7h"
                      style="opacity: 0"
                    ></div>
                  </div>
                  <div class="register_inputGroup__2VkRD">
                    <div
                      class="register_inputIcon__2arpa register_passwordIcon__18fIi"
                    ></div>
                    <div
                      class="register_inputMain__3Ur5m"
                      style="flex-flow: row nowrap; align-items: center"
                    >
                      <input
                        v-model="registerInfo.code"
                        data-inputtype="register"
                        maxlength="4"
                        placeholder="验证码"
                        class="register_input__1ukwi"
                        type="text"
                        autocomplete="off"
                      />
                      <img
                        style="cursor: pointer; height: 30px"
                        :src="`/static/image/yzm/${imgLis[index]}.png`"
                        @click="changIndex"
                        alt=""
                      />
                    </div>
                    <div
                      class="register_operateBtn__12YnF register_hidePwdBtn__Qkz7h"
                      style="opacity: 0"
                    ></div>
                  </div>
                </div>
                <!-- <div class="register_ruleContainer__1h-YD"><span class="register_turn_on__1iAWg"></span><span>我已阅读并同意</span><span class="register_ruleLink__3gTRv">相关条款</span><span>和</span><span class="register_ruleLink__3gTRv">隐私政策</span></div> -->
                <div class="register_ruleContainer__1h-YD"></div>

                <div class="register_btnGroup__1vkHI">
                  <button
                    class="register_registerBtn__1set3"
                    @click="register"
                    type="button"
                    data-key="yb_rgstr_btn_tap"
                    data-analytics="button"
                    data-label="注册"
                  >
                    注册
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      style="display: none"
      id="app-footer"
      class="footer_footer_bg_hidden__1QCjG"
    >
      <ul>
        <li class="" data-analytics="button" data-label="首页">
          <i></i>
          <div
            style="cursor: inherit"
            class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j"
          >
            <div
              style="
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                box-sizing: border-box;
                margin: 0px;
              "
            >
              <img
                class="footer_icon__1Rh5j"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEVklEQVRoge2ZT2zbVBzHAxoIEBxgAk0CIY4gxAWkSQghgcQFBDshIXFjlzEIF24goQmhHcoJoYnTOHBaWlg1mO2kSZv37KSxn5NtbVBI/ziJnffshGRts7GtZRR+yKUJiZtgu423TuQnfQ+R8/Q+3zy/9/vaCYVGNapRDbUKBbgXE/YxJuw8JqyKFbqCFYYwoV/OztcfC+3nQln6CiJsASkU+gkTuoZVdhwA7grttxIJO4YJ+3MQfK8R9h0COBDaL4VV9pkX8G4lZcrnctYDtxUcAO5GKj3lF356lgKHDeCxLk8TdvB2btZxJ5w6X4elyhpIKusPn64Ch/R/hfViIm09eUvhUaHxIFZYwgk3V2zA7zc3wa7FyuoO+IQTHrVlsFjaePaWwEsXa48iheWccPmFJvx2/Sasb/yxJWWu1nM9nhoEr2+vhLEqSMZLgcKjS7WnsMKWnPA/LzZhpbUOrasbsHZlA4gDfsoNHnVM3IiK1SOBwIu5+nOYUKsfvNW4Bo2V61BrXIPZC2bP9ZhXeNS5nTYFsXp0qPCYWC/bTcgJP7/QhAq9ArR2Fcq0BVK2d+NGJcMnvN6RgKufDgVezJpHsELXexqRQuFi4VdYKK+CZqxBUVuxu2wvvLh7eK5jwvh6T11bVMyjSGGbTvhsvg7zxSYUli7D3C+N3tNGHg48ty0eGRH7yPYNj1Xzg37nuH2Pq/n61gqo872bNSlTEPBwwLneXsEj5CN6YIW9OijXpHImyJesHZt1RqbAiwHAIx3OJ3WI8NpXPgzQ/H+Hsd7PMxlqx4JA4H9KVuAMvwwRTtv8YarytCt8SrFe8JVr5Kq9xIHBR7hlOLOtcU476f7rE/Mjz/CZ4ODPzVQ64B3xy9iDAXbSW6IMEH66vBOeW7b3waIHA3TMDT4xawQGPxkv9YXfVmnPBuI2fADgHHaF37uBeCo4+LNTrvB7MzAVFDzS4fuoJ/jdG4h5CWVYN+zswmPjEzdxWP+GQ3r9H3jNK/zuDMQk91+QR0Y6nW4+FPJRsQx9ZELQ8j7g/RlI+ojDMYkebo/PULgfZc3XkMLe7padajNzlx/vnms8WnojEANJuQqCj1yTy8E9ncdNQrWBEUSh61g132rPNcFXDg3fgEzHBJ9xuD1WJOYJtx5i56z29yen2cGhGxAkY8zvKdIeiwg97drFCW0FaoDHIwMwWgFudAvp+3ITn42X79xNPBkvAVaqd6aBc4ny1usaTKinPvD5fjLw43Sl+2VCwdUAlunrvA94Huu19lhRYV+4d2JW7BhGcCDCaTcGwdsP9Q7zp0NeKpmh33p5ZBRE46+ZDA13gBT2BCa0NtgA2xSJ9W73XON86UQ/eA454dkCUhuHQl5rJkMPR0XjmID0cD/xUvV9KWc97xyXyrceFlXzHftPv25h1XxPvGA902+uiejyixGhdDwiaOEJQQvHJD2MiLklUTE/RIS9iSpwn2f4UY3qf1R/A/1BRmX2Ea0WAAAAAElFTkSuQmCC"
                decoding="async"
                style="
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                  object-fit: cover;
                "
              />
            </div>
          </div>
          <span class="">首页</span>
        </li>
        <li class="" data-analytics="button" data-label="优惠">
          <i></i>
          <div
            style="cursor: inherit"
            class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j"
          >
            <div
              style="
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                box-sizing: border-box;
                margin: 0px;
              "
            >
              <img
                class="footer_icon__1Rh5j"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEUElEQVRoge2ZTWwbRRiGDRTooUJAhUBISIgT4lCERLjCCfGjnkAcEIgLEj30hoRQhUAcoBIXuJBL4YCEZOMEldLZdVLbmVm7dnbWTtsEWYmwY+/uzHpdJ2liuQ0OpHxoNzGEFLx/Y35Uf9J7sOSdfZ7RzOzndSw2qlGNyrMA4BaisQ8JZU1CeZtQ/hXR7LGYgCpWOvcSar2DKV8ilHUIZal86fIjMZGFNf4eVhnsD6F8VqHNV8tluD3omLk5doRQdoqobPPGcVkdAxwQJuDM/F8J7LlhU6HWB1hrPzBoHAC4LVdiL2HKyKDxsrMMZGIcFQKPK+1Dg262T2QLU/Z1rmQ/tXcMSvlhrLF3CeWm1xjZIgOJ6CAr5ttCBFwJlXG/EntkTrnXltgzROVdP9dkCyZIWAfkJKe/KExA0fgrWOXbwST4Nm7AQULZl36+nykwQMRw4SWip5yDQ5iAK1Gyntg5ifi4r6j8dec6XG4+qmj800HfTRfMcUT0cQkbnyFsvObsFaHw/9sCgFsrFbjj30o5xPHsFtaaz2GVlwllPwfdvH4znd9Z78gjEjGYRIxPMIaDvuAVtfnssKD7mfIJj/aKYGPSl4DzdB0qfC44PNpNKseOeC8fynpDgZ81IZULB476AorxhrfAEOBnVAZyhJlH/WWkGG/94wIzTl+jRANHQQQI5UwoPBEDj9wlxF7wFlD55yLgnY5SEgiPsLGK8frdngKqunYXobwQCb5oCoWXsL4hE/Z8LMgTmGjWUaJa7yuqdTJIskV2UlIMMSHGxxLW3zx33nwwdlNXfmHjntwF+z6vyDlxKYftg/rltLSE8hNY5S2vNZ8+bwrcrHp/3fcQ0aXTuPFwKAFC+Ud+Nuy5IcCjHQGYnFqGhFRrJHH7UCD4ahXudH/f7kLSeRt4qwut1WvA7S40eAd+bKzDwtIq0PkW5EtNkJXoT1rUD9FhYmoZ4qjq5htp+VgggTzlj/fhSwstuH79V+htbUOnuwXttU1XxhG4tLgC6qUWEGpBSkCrgHZnfiJV+x3eSQJVx4MtH80e6wu0Vq7BZu8XWNv4CWrGOizW1mCu0obiBRuIZkG6IK5VQFiH5D54V0CqfhFaYOXKJqx3ei648zklcqngffDSn8GFCFiXu2C3r8LC4spQ4RN/Ax9ZoME6booX7SHBNyA+AD6ywNLyFZhfWnXegwqHP4t1T/hIAs5rvtIPbZi92IJ0Qex5f3am4ZwunvChBdyOEuugaBbMqFxod/l91j98PJRAwR5zHibOzcT29DqcydR9g8fDCqSIOTaMDftdOjh8/L8icDr9R2swdAEJm08Kgyc6fDsdHj4eRiBD+WFR8JMR4eNO5OqJWNBC2MhEFZhIRYdPoOpWUjYeCywwrVgPIWLMhZ35pHxjUxY4UvVqXKq6/zOEKozhgJxjT0vYPCZj/bjfJKfqxxNyLVKScv3lM5n6/aHhRzWqm6R+Az1Dmn8oNv4FAAAAAElFTkSuQmCC"
                decoding="async"
                style="
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                  object-fit: cover;
                "
              />
            </div>
          </div>
          <span class="">优惠</span>
        </li>
        <li class="" data-analytics="button" data-label="客服">
          <i></i>
          <div
            style="cursor: inherit"
            class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j"
          >
            <div
              style="
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                box-sizing: border-box;
                margin: 0px;
              "
            >
              <img
                class="footer_icon__1Rh5j"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAE/UlEQVRoge2ZXWgcVRTHt1rrByr1wRfRgoKQB1+0UuyDHw/Wl2JFiyhWBYUiKFZqDBTUJ8UXkT5aBEGpOJM11obeO/uV5N7ZbDYzm9UQajB17u7OzJ3dtGnzXVONTY7cbdJusjP7kcykBfOHw8IOM/z+55577pndUGhTm9qUr1L1sw+qmeIrVHcOqxn+Jc04X1C9+L6a4S8lfxu9N3QjigxObqcZ51OqOUNE4+AVVHcWqOb0qlrxPcOAW683dyibhVvUjNNKNT5eC9zVjOYUSD8/cN3gRTlQnSe1wVGwitNwfmIOiudm4Ux+ArKnz9Y10NXHARETMLWOEwK3bSi8OsgfFhm0SzOwsLgIF+fmoTR2EUbyE5AZqg+f6LPL8GgpMDE1Qia3b1y9687Ihck5EJq5+A84o7Mw9McYpLLFuvDxVfBo2QS14gBwc/AGdB61ijNl+OnZv+HPwiQkM05DdR9PWa7w6KoJ86tA4anuvKwNluDywiJMTgv4iYY3bay3NjwSQa2FGLEfCazjUI0beXsKxqcugfhUG8x8LNkAPFneDxYKxEBygO8XMKJ8cvYUDDTQaUREm4BHSxEjpRbfDVDNOS6ARKf59fdz9eH7bYgkmwNH11bhiK/wojtQjU8IsP7BUl34Ho2DsobMo6t7wUz7aoBozv2Nbtaefg6KukZwcvVcmPLVQHJgdFfD8HR98GgpstnSHU2DipvEFEl05xjN8IMEYKv4Xh0o7qsH393PRR/3BR6J6LUeKq8+GbsTEfOjnyLsWLuSexsAbnKFHx4e3kZ1fnoFmM6jALBluQN5wqdtf+HJlU4k4DExR8LYAAktB+t0L5PM6NOuU+NAcU8tA10C3kdwVGEAU/uwjFkF/JVox/mnqgyoeuk1VwO686GXgUTaFqen7/CobKDQIinGt6vhRcgo926VAaKVXnc3UGxzM7B6ovQzThETOmKFFhmz790MtGN2aF0GEqkA4btNkJERnIF4gPCd3fkyvBSUgVhvgPBd+RWQvhuIBgh/MrES3ncD0T6+Pyj4E4lcFaCvBiJJ3hZR7X2+w1MTTsTd4f0x0M8holrihG2LJvkuv+E7Yt7w0toMFF91mygxtQ4lUqUdfhroiNaGl5AB4URuh4TYN64HGWbvuBhwnijDpzlg9drpGqHObkJgK6LmjB+ZD0eqRwOpGnAxPAzb2hV20PV61HisyoCY8nrSPIorRgOsWl8vX8fU/NkLDFNLRUlzb62IJs294WhubxhdiXbMPvE2YIyVk0pgq4xZz8oxwjhaBV9hYotCrecV1W6NEP5M5TVM7be8s2s5oSYlYfaZ9yqwE5WJlbHxQrvCWqVo/snQWpVKnb8LEeuC5yoQ+/FGnyUSJWE25LkCCnszFIRER/IuI3Monea3N/IcGeeOeGYfG9MIWfcEYkAAYmoWapTSD/V+qBXlICN22TP7iH0cClKo19qJqXnJu9NYBqbWntX3dcbt+yTMfqzZfRCzTq3lXbhZ1drQnd2F5VLgMmaK2JAyYlkJsYU68H91KOajgcNfM2G9gak5Xwl/smsJvtnAxnw4wl4MbbQQsZ7F1BwV8L+4TJSNhIyNsXW1x/VK0cbv7kwUjsrY+Lc5cLYoIyZJMfZA6EaQmF1EB5ExO1MH/oKE2HdhxHaGblSJrMpK/jkZ5z6QFfa5jFmbrOQOhCPG7vBG/AuzqU39j/QfAdrRLU83fWwAAAAASUVORK5CYII="
                decoding="async"
                style="
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                  object-fit: cover;
                "
              />
            </div>
          </div>
          <span class="">客服</span>
        </li>
        <li class="" data-analytics="button" data-label="赞助">
          <i></i>
          <div
            style="cursor: inherit"
            class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j"
          >
            <div
              style="
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                box-sizing: border-box;
                margin: 0px;
              "
            >
              <img
                class="footer_icon__1Rh5j"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAESElEQVRoge2YT2gjVRjAo4J7EPGkIq4HEQQPihfBVe8qiIqeBA+exJPoxbMIiuJJWQQvenClaberrM6bSZqm75tp08wkbbe1pstu0mbevEmTNO12i+2mNm0+maFLp900mTeTtCvkg4+Qf+/9fm++mfnmRSL96Ec/2sa4YT8Nhv0l6PZlanDoeaY5yKopE2DfKWCfiwQNRLwPjNJn1ODbVOd4Ejk2yZGoDCVqukmANSUwf4jPVR4QFlAz/NuTAqc6x6QDDwfw3iTA4oLwpZfAsPeOTgIGx+xfFcxdX8X5azWcXVjBqfkKpmeWQ8JbSFqAH0qVfehbwK33I5MsFNZwp7GH2//u4vrGNpZXNrHIN9zPZ3JV1DJ2MPgURwk6wLtHwSz6FzDsmncS095AJ+rbDbyxUcfllU1csm7iQn4Np/+uYmq6FAh+NGX5gr+dMc18zK9A8/YkDmCz2cRb9QaWqptozJbdUgpb84kJMXjJOQoqf9aXgHciB3qr3sDq6lbgMrkTvvXJKnVIRePPCQvU1m7h6nrdLZduwI+MB4OXggpUalvIy/+4pSQKC4a9BQafp7q967yPh4CXggo4J/D14jrqs2UxeN2uqpnSE+54hv1GbNzaDQMvBRW4uriGc1drODEldqWBDP/YO6ZE2YVTEZjJVVG/Irj6hj2Zz+MZ75gytc8SMMsnLqBlxa48oNs0NVd5pNW4ClgvEDAXTlSAdlxtvgO6/RM17I/U6eVn/Iwva+xlmbJPCLBBt2E7TQEty9+NhAgC7JtTFVCNypNhBCTK3vcrMKwsdV+AZvj5oPByHs8QYFk/8IOk0CMBpynTra+CPDBJlMU6wxcxSvI4IOV7I+B0lDKwpkLZKyICBKxPO8H/SU2MkoIL3xOBhNMO709GgCX9wiPivYSaN9vCjxUxug/eE4ERpx32TEioafoVGNWrj7aD/2OseAi86wIj44fh949A1q9ALof3E2CNVvCXk63huyZwfEfJLkQEgoB5TQQ+vECaY0xr0w4DS3jHSE7yxwm1PpAo+1pS+TuJqRsPec8BCVjJ+//fE0tt4UMJjOkcY579mlbptASEss8VzXpVAvbj0RJxT1qwvlBU600C5q/e734b6QwfXCDNUVGDd5Cd8lJ80Rd8IAFn5eUOKx8mh2P+4YUFxtLuDao38GDixdjBDWqg2wLJtHXsNl83ckgRW/kBUQEiuF/jN53tw0FZfOUHRAV6UzYHTdn/SwDYnvN6tK+5qwUImDMErPdkrfzwxETtwYty4fWoVIjd9QLEAdest44bNyoXXwwjMtwrAQdcptbbvgYPITLsWwBYzSf4FRHw0CLxgrvT1zEImJf8gCPiPZEuxJCSPxcl+Xg7+ChZ9P28EUmmrKckMNd7De5XJCrnd0Yn+WsRkaC6fZZQ67wETCPAfnG6yF6BtxSRC99HpYI2pBR+pin7+Tt+1I9+9CPixH88983PRtjROwAAAABJRU5ErkJggg=="
                decoding="async"
                style="
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                  object-fit: cover;
                "
              />
            </div>
          </div>
          <span class="">赞助</span>
        </li>
        <li class="" data-analytics="button" data-label="我的">
          <i></i>
          <div
            style="cursor: inherit"
            class="image_imageContainer__2dKjX image_cover__3B5D- footer_icon__1Rh5j"
          >
            <div
              style="
                display: block;
                overflow: hidden;
                position: absolute;
                top: 0px;
                left: 0px;
                bottom: 0px;
                right: 0px;
                box-sizing: border-box;
                margin: 0px;
              "
            >
              <img
                class="footer_icon__1Rh5j"
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEPUlEQVRoge2XT2gcVRzHp2gVKoIUBFvBgxdBvXhSg3gRKRVET4IKepOKCJ48eVAhCIIoEk+CByPsJE2KJe/NbppN3pvtZrOTTFMLbdXMbPa9nbfbNdsmsUla3dL85M0mm7HMrrM7O7MV8oPvZfYPn+/v/X6/9xtF2Y/92I+eRcasHdHz4j1qON+SvBijhpOkefEDzYvP9fnKiwBwj3I3BjXE89QQM9QQ2yTvQCtRQ9Sm58Qnplk5pNwNYZqVQzQvhttBezWdcwBTBpjySjLDX+orfHaxdpQaYjE4fAkwYYB2hCm/pdHS+/3LvA/84sU/QFQ3YPNGHeq3bsPGVh3OLghIzzqAKG/Ce4V1/kbsBojh/OQFl5DlHfA7lTXLvuBNUb6lZfiTscHr8+UBL/zsuTJUa1uwun7TVW31BhRK63Bp6SrkzlXaw5PdcmJabAaI4dC9qeIAE3/CldqmK4utQWZBuJ+dOVsKBI92lCT8hbgatzkqz19eAV6+7urCb7XmqUx2CI8ap/Bd5AaIIU54y+f35VVYKq7BZeta81mqC3jkiovoDcw7Q7ugsjkvLl11JftAPktm/CdNUBGy9lCkBqjhnPI2rxybUi68Hg4eEQaRTyOaF1Pe0Tl3vuJOml7AI2mAiOeiNWCI0TsvL42GB0e7kyhbfiJqA4PN1WDOkbdoz+ARZX9rFtwfrYEF5/jeUvZveI3yV00TDv6XsM6/aGEgp0Qdcp+fmSstI5+ywZR/GeQ/MOWzvvWv83eUOAJl+IctMrg2la081u63GnWOY8q3/e4A04SDsRiYvFB9ABFW9b1NCctjsvKIL7xefgZTtuI/ffhHSpyBdDaACfvL/yT4qkb5x/I70znnUUz5y0jnX8v939e0zsYB4IASd2DdeSvs5ME6Nyf6+Xqp6extWfvdjU1+elS78rDS72iUCUsGzjph60jn7/YFFgAOJDOlY5jwbzDl84jyMqbsJqasHjjzM/y2iu16AlubKrK5iuy0qlmfqZP205HCyzmNKC+EqfkJUgQVWZBoIRXZmTFcfLan4Nls7UFEeDpsw07MFFuCJ7wmsL0tT6Qn8HI/l7M9LPzp6WDwCa9wYSjUeE3lnMOIcDM0fLoLeLRrwhru2gAmfCws/M9nlruHRw2N4MKJjuGTuvNKWPhTU+HhE25jW9dHU87hzrJPuR4Gfnyy0BP4xF4pfRoYPpXlT4WBP5nqMTySsquEwL2BDCDKBruGT9oRwFuNXtDs14OWz0I38CPJKDJvecqoMBTwBPhG5/DRZT7RbGabBC2hXzuCx9HDJxo6GbSEvg8GXwQVxwIObg9g64NABlIZdgRTfq0d/ARhcrTFBq8i65fRS3BfIANuGelsoNXmKZeydhtlz+GxnR1P88eVbl7eMS29iSn7ClM+LIUIG5b7SdRSsf3jCLIHVWy91pf35f3YD+X/E/8AJUVb1128F3IAAAAASUVORK5CYII="
                decoding="async"
                style="
                  position: absolute;
                  top: 0px;
                  left: 0px;
                  bottom: 0px;
                  right: 0px;
                  box-sizing: border-box;
                  padding: 0px;
                  border: none;
                  margin: auto;
                  display: block;
                  width: 0px;
                  height: 0px;
                  min-width: 100%;
                  max-width: 100%;
                  min-height: 100%;
                  max-height: 100%;
                  object-fit: cover;
                "
              />
            </div>
          </div>
          <span class="">我的</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "login",
  data() {
    return {
      registerInfo: {},
      loginInfo: {},
      imgLis: [
        "2PYL",
        "6AQ5",
        "8PHD",
        "21I7",
        "69HM",
        "ACWA",
        "DUZ7",
        "IY98",
        "K647",
        "M52T",
        "NY52",
        "NZFA",
        "SN76",
        "SP4D",
        "VAEO",
        "YFQM",
        "ZZU5",
        "7GQT",
        "LFW3",
        "NU2T",
        "UAE3",
      ],
      index: 0,
      infoType: 0, //0 是登陆 1是注册
      psw1: true,
      psw2: true,
      psw3: true,
      pid: "",
    };
  },
  created() {
    let that = this;
    var query = that.$route.query;
    if (query.type) {
      that.infoType = query.type;
    }
    if (query.pid) {
      that.pid = query.pid;
    }
    that.changIndex();
  },
  methods: {
    changPsw(name) {
      this[name] = !this[name];
    },
    changInfoType(type) {
      let that = this;
      if (that.infoType == type) {
        return;
      }
      that.infoType = type;
      that.changIndex();
      that.loginInfo = {};
      that.registerInfo = {};
      this.psw1 = true;
      this.psw2 = true;
      this.psw3 = true;
    },
    changIndex() {
      this.index = parseInt(20 * Math.random());
    },
    register() {
      let that = this;

      let info = that.registerInfo;
      console.log(info);
      if (!info.name || info.name.length < 6) {
        that.$parent.showTost(0, " 用户名长度6~16位，以字母或数字组合！");
        return;
      }
      if (!info.password || info.password.length < 6) {
        that.$parent.showTost(0, "请输入正确的密码长度，最少6位！");
        return;
      }
      if (!info.confirmPass || info.confirmPass != info.password) {
        that.$parent.showTost(0, "两次密码不一致！");
        return;
      }
      if (!info.realname || info.realname.length < 2) {
        that.$parent.showTost(0, "请输入您的真实姓名!");
        return;
      }

      if (!info.paypassword || info.paypassword.length < 6) {
        that.$parent.showTost(0, "请输入正确的支付密码长度，最少6位！");
        return;
      }
      let code = that.registerInfo.code;
      // if (!code) {
      //   that.$parent.showTost(0, '请输入验证码！');
      //
      //   return;
      // }
      // if (code.toUpperCase() != that.imgLis[that.index]) {
      //   that.$parent.showTost(0, '验证码错误！');
      //   that.registerInfo.code = null;
      //   that.changIndex();
      //   return;
      // }
      that.$parent.showLoading();
      if (that.pid) {
        info.pid = that.pid;
      }
      that.$apiFun.register(info).then((res) => {
        that.$parent.showTost(1, res.message);
        if (res.code == 200) {
          sessionStorage.setItem("token", res.data.api_token);
          that.$store.commit("changToken");
          that.$parent.getUserInfo();
          that.$parent.openDaoTime();
          that.$parent.goNav("/index");
        }
        that.$parent.hideLoading();
      });
    },
    login() {
      let that = this;
      let info = that.loginInfo;
      if (!info.name || !info.password) {
        that.$parent.showTost(0, "请输入您的账号和密码！");
        return;
      }
      let code = that.loginInfo.code;
      // if (!code) {
      //   that.$parent.showTost(0, '请输入验证码！');
      //
      //   return;
      // }
      // if (code.toUpperCase() != that.imgLis[that.index]) {
      //   that.$parent.showTost(0, '验证码错误！');
      //   that.loginInfo.code = null;
      //   that.changIndex();
      //   return;
      // }

      that.$parent.showLoading();
      that.$apiFun.login(info).then((res) => {
        if (res.code !== 200) {
          that.$parent.showTost(0, res.message);
          that.$parent.hideLoading();
        }
        if (res.code === 200) {
          sessionStorage.setItem("token", res.data.api_token);
          that.$store.commit("changToken");
          that.$parent.getUserInfo();
          that.$parent.openDaoTime();
          if (res.data.is_Initial_password == 0) {
            that.$parent.goNav("/index");
          } else {
            that.$parent.showTost(0, '请修改您的登录密码！');
            that.$parent.goNav("/password?type=1");
          }
        }
        that.$parent.hideLoading();
      });
    },
  },
  mounted() {
    let that = this;
  },
  updated() {
    let that = this;
  },
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
//@import '../../static/css/79a97583f57023fe4fa8.css';
//@import '../../static/css/438b8f8fe59c79885d4c.css';
//@import '../../static/css/4186aeadfd68d65ea7ad.css';
#__next {
  width: 100vw;
  height: 100vh;
}
.entry_entryStyle__FXwIQ {
  width: 100%;
  min-height: 100%;
  background-image: url("/static/image/entry-bg.jpg");
  background-size: cover;
}
.login_formContainer__2zhUW {
  width: 100%;
  border: 1px solid red;
}
.login_formContainer__2zhUW {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 80%;
  border: none;
  margin: 0 auto;
  border-radius: 0.2rem;
  overflow: hidden;
  padding-bottom: 0.2rem;
}
.login_formContainer__2zhUW .login_normalContent__1I3Xg {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0.2rem 0 0 0.2rem;
  background-color: hsla(0, 0%, 85%, 0.4);
  padding: 0 0.4rem 0.76rem;
}
.login_formContainer__2zhUW .login_normalContent__1I3Xg .login_title__1VIs3 {
  line-height: 0.5rem;
  padding: 0.48rem 0 1.2rem;
  text-align: center;
  font-size: 0.36rem;
  color: #fff;
  font-weight: 600;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z {
  margin-bottom: 0.2rem;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z
  .login_inputGroup__1xPH8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 5.2rem;
  height: 0.9rem;
  padding: 0 0.3rem;
  margin-bottom: 0.2rem;
  border-radius: 0.45rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z
  .login_inputGroup__1xPH8
  .login_accountIcon__1eker {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0.4rem;
  flex: 0 0 0.4rem;
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.2rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAACXBIWXMAAAsTAAALEwEAmpwYAAACNUlEQVR42tWWLYvkQBCGDyIHGgKxY/MDxmVU3Mg1y5q4k/kD53a4XzA2dtyIhYVRMUPEyLFjAmPORcTFxTwXikCTrnzs3cEtWzGppvvt+uq36tvXFnwSjhSUNDSUFBxJ8P8O7IkLLWPScuHpz8Airv3RnJSYEIMhJCYl76+5En0MzOMAQEWKGd1hSKkAOOAtwRlysWzPanbfir1YmmPm4e5A9TFn2Iqdd8y0s7lsWGt7CAm1zazFgHzCcYld5cIRI2noUxQryAo4jGZWjmwdmzMAGgoKGgAyPMfxFtBB4grsh2sCV5Pg9fAJNZA5u/bAVZcxVMMosRO49dBFgdw5Ea7AKXUuQKpt5ll58qztIQUuwzfbdp9x3jHUY8kTe/xhucl5u0YC5M6xDbKmhRzY6DUSqx7FYQ14HgU8C6B2+mjVAlSFBUA16XKgqhUKq5ZAqA7ebD6d3N/UagiUVm0A/bBegF9O+P1uBV40CwDNHKAIbwIZW8cE7k2UEUDtsiaFdwAenLrvAcC7ulq5rJKi+MRKaV+OTooqG2VfRgu0lJy779FrBySuumzmC9uXLNf8wNgrOq0G7gSzha2fHkFPnTr3RuJ6w9dPb4YcyIAT3jizC+RZkcM0fbGRchFtHFIyvlP0NUWwnCQiM8J3IJ8hWKFyaIl6zvu52L1L7vIXSe63C01qWfAJbJNabKPLotvovzf6yDb65VHkdXEUebWjyH8YllTG1Ti3YqXGue2nDZzLI/GXlt8z5Efk//aSCgAAAABJRU5ErkJggg==);
  background-size: contain;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z
  .login_inputGroup__1xPH8
  .login_inputMain__18b4V {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  z-index: 2;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z
  .login_inputGroup__1xPH8
  .login_inputMain__18b4V
  .login_input__hMBpm {
  caret-color: #fff;
  display: block;
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.28rem;
  color: #fff;
  padding: 0;
  text-align: justify;
  background-color: initial;
  border: none;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z
  > div {
  background-color: rgba(0, 0, 0, 0.3);
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z
  .login_inputGroup__1xPH8 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 5.2rem;
  height: 0.9rem;
  padding: 0 0.3rem;
  margin-bottom: 0.2rem;
  border-radius: 0.45rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z
  .login_inputGroup__1xPH8
  .login_passwordIcon__foPzR {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0.4rem;
  flex: 0 0 0.4rem;
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.2rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2ElEQVR42tXWP2vbYBAG8ELopsEfIFm9ZhWeDNmyZA5oLBQ0Z/bcyZ8gk75CF02avJYsmfwJNBa8afn1xRTUtxdbqRIouUMgPbr3QfdHd/fpY4uFSqOzd0i612lUFvPI7nQGoiS0c/dvZKXd76Ot2tpSkXRprdYmFHbK15Fd2IJerTgRiFoPti6mo9ZisFGctStsDGgtztM9o3+dM1Z6PFucdrY9GlwFfOVr0tXfDrryjPaIR7FFH+jK46GjpLsyUPbYvuwAQzhwn7C9yjJpZW9wH84NWEXCHTY55tJPnWJMhS4hl7mVDXaxjOkVOerRwVXuYkIeQ8Z73OVghzp8da8NWKsPWI0uL5chaai8hH0L2IOngBXH84sRqNDGRI1RnRItqvGxQf0mwhpNHsF1ZvBZ6RaN2xe19DmzX2dRtMcyi8mTKXn6M+aW2I+PB7LXX0hXeUaPFtkncDhNuMHFRJNjEwiDyzMIg8tjUmYQhqSEsplBGMomFPYswhZV+PVmEIZfLzSHGYRjc4jtawZhbF+xwXrAYmI68hAa7KkR4Nqg9+OM9gbX4whQTgwpNxrfz2jjJgypiTE6IXGMvn3Ql2HQv+8qMn9ZKsKy9E7r3Oq/LZzTK/GHll8m3loGX3l4/QAAAABJRU5ErkJggg==);
  background-size: contain;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z
  .login_inputGroup__1xPH8
  .login_inputMain__18b4V {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  z-index: 2;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z
  .login_inputGroup__1xPH8
  .login_passwordIcon__foPzR {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0.4rem;
  flex: 0 0 0.4rem;
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.2rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2ElEQVR42tXWP2vbYBAG8ELopsEfIFm9ZhWeDNmyZA5oLBQ0Z/bcyZ8gk75CF02avJYsmfwJNBa8afn1xRTUtxdbqRIouUMgPbr3QfdHd/fpY4uFSqOzd0i612lUFvPI7nQGoiS0c/dvZKXd76Ot2tpSkXRprdYmFHbK15Fd2IJerTgRiFoPti6mo9ZisFGctStsDGgtztM9o3+dM1Z6PFucdrY9GlwFfOVr0tXfDrryjPaIR7FFH+jK46GjpLsyUPbYvuwAQzhwn7C9yjJpZW9wH84NWEXCHTY55tJPnWJMhS4hl7mVDXaxjOkVOerRwVXuYkIeQ8Z73OVghzp8da8NWKsPWI0uL5chaai8hH0L2IOngBXH84sRqNDGRI1RnRItqvGxQf0mwhpNHsF1ZvBZ6RaN2xe19DmzX2dRtMcyi8mTKXn6M+aW2I+PB7LXX0hXeUaPFtkncDhNuMHFRJNjEwiDyzMIg8tjUmYQhqSEsplBGMomFPYswhZV+PVmEIZfLzSHGYRjc4jtawZhbF+xwXrAYmI68hAa7KkR4Nqg9+OM9gbX4whQTgwpNxrfz2jjJgypiTE6IXGMvn3Ql2HQv+8qMn9ZKsKy9E7r3Oq/LZzTK/GHll8m3loGX3l4/QAAAABJRU5ErkJggg==);
  background-size: contain;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_btnGroup__37-ja {
  width: 100%;
  text-align: center;
  height: 0.9rem;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_btnGroup__37-ja
  button {
  width: 100%;
  height: 100%;
  line-height: 0.9rem;
  border: none;
  border-radius: 0.45rem;
  font-size: 0.32rem;
  font-weight: 700;
  color: #fff;
  opacity: 0.5;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#62b3ff),
    to(#3d72fa)
  );
  background: linear-gradient(90deg, #62b3ff, #3d72fa);
  vertical-align: top;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_visitorBtn__2xo1h {
  margin-top: 0.3rem;
  text-align: center;
  height: 0.34rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 0.24rem;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_visitorBtn__2xo1h
  a {
  font-size: 0.24rem;
  color: #fff;
  vertical-align: top;
}
.login_formContainer__2zhUW .login_locationToRight__gIQoN {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
  border-radius: 0 0.2rem 0.2rem 0;
  background-color: rgba(0, 0, 0, 0.4);
}
.login_formContainer__2zhUW
  .login_locationToRight__gIQoN
  .login_locationContent__o3pkw {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.login_formContainer__2zhUW
  .login_locationToRight__gIQoN
  .login_locationContent__o3pkw
  .login_linkRegisterIcon__3AVOn {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0 auto 0.2rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAACXBIWXMAAAsTAAALEwEAmpwYAAACkElEQVR42uXXIWgrSxTG8S3E1V1dEVVRqKm7pipwRUxVVdR1haoLUVFxtVfFBEJMfMWaQE2gEFEZEVNTVbMisBCx4vegd3mPJjPzNo158P4x2dnDfjvfmXN2Jvs/4MSVgYm5lY3S2pOJoe9OsmPxw9i7GO/Gul+WcW0JQP3mUxNzayUAXnSyQ3EuB7A0cBmI6HtWAXLnWXN0bUBlqp2MPDNSgY1u1gx9FchdNpztI6j8ahI+ApV+4F5L25lW4M6dCoz+9e3D03WiZ6ECrP3e9VxHAfpp7ytsXO45/WyHfUNcKFDpxr3chAJcKYTp7S3sLd60wgJ5aIraCjHene5ZXLiKlRXke+MzoOEcTqL1YIkqUFAbKUbNew5M98a/SZM3FRiDdqCTVlI8Zjs4NdLff8w7lkHpVynGe/E9cLa7EGEQFJiKEC4r9+Di8+AAwp3HnRQ3zQQmKCNfs1yKYTOBOdYRgUqKxd+p/aH78RuB+z9XtStWeIrWR4qHOupZhH+KaRoR6ElR580iLVBiEhFoeUsbVFvU+WTR3Z+rOhfWmGcRPKQ6UbMkP2EVFfguTOW0qcAERVSgZRszqKnAsB6M4FGI+8SiOAuZ0E9swSq7vNUGhZrdr3Cze86i+OkzW+fZIZig0k7koQQAL9lh6IJpIqIAAKvDt+gvqGKJ9s0WAGycRjeT1+EbHZBH5Md2eYiuuDImMQf93V2Cn5ZCzPV2s+aWaON0oUSl8/G/L7dWIk1pLdd3/vFlLJMrzA0ozH2F3FtdgHEMHMvvBhVxBLPUie34WQwbHgjdKB3K1m3WHJeeHMLC1VfOyC+asNL9+hn/xlQhRmHmNu57c5lrQzMLr7a2Xi3MPOiETzJHy2X/Lf4CyRcQ3Z3pfQ4AAAAASUVORK5CYII=);
  background-size: contain;
}
.login_formContainer__2zhUW
  .login_locationToRight__gIQoN
  .login_locationContent__o3pkw
  .login_text__-W8Ok {
  text-align: center;
}
.login_formContainer__2zhUW
  .login_locationToRight__gIQoN
  .login_locationContent__o3pkw
  .login_text__-W8Ok
  span {
  display: block;
  line-height: 0.4rem;
  font-size: 0.28rem;
  color: #fff;
  font-weight: 600;
}
.login_formContainer__2zhUW
  .login_normalContent__1I3Xg
  .login_normalLoginWrap__Q4P0O
  .login_inputBox__3j84z
  .login_inputGroup__1xPH8
  .login_inputMain__18b4V
  .login_input__hMBpm {
  caret-color: #fff;
  display: block;
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.28rem;
  color: #fff;
  padding: 0;
  text-align: justify;
  background-color: initial;
  border: none;
}
.register_formContainer__3DhlE {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 85%;
  border: none;
  margin: 0 auto;
  border-radius: 0.2rem;
  overflow: hidden;
  padding-bottom: 0.2rem;
}
.register_formContainer__3DhlE .register_locationToLeft__17bW- {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
  border-radius: 0.2rem 0 0 0.2rem;
  background-color: hsla(0, 0%, 100%, 0.3);
}
.register_text__33mcY {
}
.register_formContainer__3DhlE
  .register_locationToLeft__17bW-
  .register_locationContent__2IbiZ
  .register_linkRegisterIcon__bUBqC {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0 auto 0.2rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiklEQVR42uWXLYziQBiGV1SuW3cCgSFZsWoVDnMGgziFQa/CkKxCYteSbFJRVYuoaEgwFSjMBVGDwKAQTRpSUfNccpBM2m++Abrr9nFt35nO9/NOpw8/A16Z4rNkS05OyoqAKd3vmPo3nxzQOOLTbz55jzWG02XlPktScgwbevdP3iHCTDDlRSieeccsIKZzz/R9cs4EtJ3KX8wpAchvThYTzsS83BjtgjOTW+RzXYxHixaefMLbJY751dVr4TIioeRMygdt0W/Z1SjoA5DXU0OLhDolY1H2DIC+nsvcJuCVI3aGIooSyJWOIrKFSJvMYbTHmnoMQGy1lf0RIS6GQr8AQFrvYhppqBwXc5loSmAj9xyAQMifcBM9CPi8VFLetLkWNwuru0uxWA7nsCTscOFbxyTAsdqIAFOrOMDFu8OuXXNjiimw3ARcDBRHAczMDR84KfaLcTFTRh2B0FwugdQq9HCTKC/4W3lGCqwU6bpRBBGwq5opUKQjFEzdFC8U1Rf4itRjj0bi/KqU1RTFqvgDjZE6ZgHszeUK2KriLnZKHtUxGyCpmilTxR6FkiAVDkBYN9qzKo+wMVb1rbrRusb2NuhRUudgEqS4v1d33loo9WYt6DjUMZBVTh/4ALQddcgBw8Yx/RMFEIrzhGI2E6NB9pxs6z+isVyF5qlWhYxHtcCFWYD8aEbKMP/mXShQz0YsrceWDm+ssREzpC2aQXcIz5yAkt7lcB6RcuIaOSkREzr/v4wFUKiHZgYAHFnShIiDOSspMKUhem1kORsjul+L4vtXL2tx4l4KLfdaR624h4SXJv/IG25h+5V/5QEBRzQyQrPnNAaPLjNCEnYUlOxJCJnRw3v4AfwDD2rwa9aIv18AAAAASUVORK5CYII=);
  background-size: contain;
}
.register_formContainer__3DhlE
  .register_locationToLeft__17bW-
  .register_locationContent__2IbiZ
  .register_text__33mcY {
  text-align: center;
}
.register_formContainer__3DhlE
  .register_locationToLeft__17bW-
  .register_locationContent__2IbiZ
  .register_text__33mcY
  span {
  display: block;
  line-height: 0.4rem;
  font-size: 0.28rem;
  color: #fff;
  font-weight: 600;
}
.register_formContainer__3DhlE
  .register_locationToLeft__17bW-
  .register_locationContent__2IbiZ
  .register_linkRegisterIcon__bUBqC {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0 auto 0.2rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiklEQVR42uWXLYziQBiGV1SuW3cCgSFZsWoVDnMGgziFQa/CkKxCYteSbFJRVYuoaEgwFSjMBVGDwKAQTRpSUfNccpBM2m++Abrr9nFt35nO9/NOpw8/A16Z4rNkS05OyoqAKd3vmPo3nxzQOOLTbz55jzWG02XlPktScgwbevdP3iHCTDDlRSieeccsIKZzz/R9cs4EtJ3KX8wpAchvThYTzsS83BjtgjOTW+RzXYxHixaefMLbJY751dVr4TIioeRMygdt0W/Z1SjoA5DXU0OLhDolY1H2DIC+nsvcJuCVI3aGIooSyJWOIrKFSJvMYbTHmnoMQGy1lf0RIS6GQr8AQFrvYhppqBwXc5loSmAj9xyAQMifcBM9CPi8VFLetLkWNwuru0uxWA7nsCTscOFbxyTAsdqIAFOrOMDFu8OuXXNjiimw3ARcDBRHAczMDR84KfaLcTFTRh2B0FwugdQq9HCTKC/4W3lGCqwU6bpRBBGwq5opUKQjFEzdFC8U1Rf4itRjj0bi/KqU1RTFqvgDjZE6ZgHszeUK2KriLnZKHtUxGyCpmilTxR6FkiAVDkBYN9qzKo+wMVb1rbrRusb2NuhRUudgEqS4v1d33loo9WYt6DjUMZBVTh/4ALQddcgBw8Yx/RMFEIrzhGI2E6NB9pxs6z+isVyF5qlWhYxHtcCFWYD8aEbKMP/mXShQz0YsrceWDm+ssREzpC2aQXcIz5yAkt7lcB6RcuIaOSkREzr/v4wFUKiHZgYAHFnShIiDOSspMKUhem1kORsjul+L4vtXL2tx4l4KLfdaR624h4SXJv/IG25h+5V/5QEBRzQyQrPnNAaPLjNCEnYUlOxJCJnRw3v4AfwDD2rwa9aIv18AAAAASUVORK5CYII=);
  background-size: contain;
}
.register_formContainer__3DhlE
  .register_locationToLeft__17bW-
  .register_locationContent__2IbiZ
  .register_text__33mcY {
  text-align: center;
}
.register_formContainer__3DhlE
  .register_locationToLeft__17bW-
  .register_locationContent__2IbiZ
  .register_text__33mcY
  span {
  display: block;
  line-height: 0.4rem;
  font-size: 0.28rem;
  color: #fff;
  font-weight: 600;
}
.register_formContainer__3DhlE .register_locationToLeft__17bW- {
  position: relative;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 1rem;
  flex: 0 0 1rem;
  border-radius: 0.2rem 0 0 0.2rem;
  background-color: hsla(0, 0%, 100%, 0.3);
}
.register_formContainer__3DhlE
  .register_locationToLeft__17bW-
  .register_locationContent__2IbiZ {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.register_formContainer__3DhlE
  .register_locationToLeft__17bW-
  .register_locationContent__2IbiZ
  .register_linkRegisterIcon__bUBqC {
  width: 0.48rem;
  height: 0.48rem;
  margin: 0 auto 0.2rem;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAAACXBIWXMAAAsTAAALEwEAmpwYAAACiklEQVR42uWXLYziQBiGV1SuW3cCgSFZsWoVDnMGgziFQa/CkKxCYteSbFJRVYuoaEgwFSjMBVGDwKAQTRpSUfNccpBM2m++Abrr9nFt35nO9/NOpw8/A16Z4rNkS05OyoqAKd3vmPo3nxzQOOLTbz55jzWG02XlPktScgwbevdP3iHCTDDlRSieeccsIKZzz/R9cs4EtJ3KX8wpAchvThYTzsS83BjtgjOTW+RzXYxHixaefMLbJY751dVr4TIioeRMygdt0W/Z1SjoA5DXU0OLhDolY1H2DIC+nsvcJuCVI3aGIooSyJWOIrKFSJvMYbTHmnoMQGy1lf0RIS6GQr8AQFrvYhppqBwXc5loSmAj9xyAQMifcBM9CPi8VFLetLkWNwuru0uxWA7nsCTscOFbxyTAsdqIAFOrOMDFu8OuXXNjiimw3ARcDBRHAczMDR84KfaLcTFTRh2B0FwugdQq9HCTKC/4W3lGCqwU6bpRBBGwq5opUKQjFEzdFC8U1Rf4itRjj0bi/KqU1RTFqvgDjZE6ZgHszeUK2KriLnZKHtUxGyCpmilTxR6FkiAVDkBYN9qzKo+wMVb1rbrRusb2NuhRUudgEqS4v1d33loo9WYt6DjUMZBVTh/4ALQddcgBw8Yx/RMFEIrzhGI2E6NB9pxs6z+isVyF5qlWhYxHtcCFWYD8aEbKMP/mXShQz0YsrceWDm+ssREzpC2aQXcIz5yAkt7lcB6RcuIaOSkREzr/v4wFUKiHZgYAHFnShIiDOSspMKUhem1kORsjul+L4vtXL2tx4l4KLfdaR624h4SXJv/IG25h+5V/5QEBRzQyQrPnNAaPLjNCEnYUlOxJCJnRw3v4AfwDD2rwa9aIv18AAAAASUVORK5CYII=);
  background-size: contain;
}
.register_formContainer__3DhlE
  .register_locationToLeft__17bW-
  .register_locationContent__2IbiZ
  .register_text__33mcY {
  text-align: center;
}
.register_formContainer__3DhlE
  .register_locationToLeft__17bW-
  .register_locationContent__2IbiZ
  .register_text__33mcY
  span {
  display: block;
  line-height: 0.4rem;
  font-size: 0.28rem;
  color: #fff;
  font-weight: 600;
}
.register_formContainer__3DhlE .register_registerContent__16A8K {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 0 0.2rem 0.2rem 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 0 0.4rem 0.2rem;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_registerTitle__gkT0e {
  line-height: 0.5rem;
  font-size: 0.36rem;
  color: #fff;
  font-weight: 600;
  text-align: center;
  padding: 0.4rem 0 0.3rem;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_inputBox__6n6TQ {
  padding-top: 0.3rem;
  margin-bottom: 0;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_inputBox__6n6TQ
  .register_inputGroup__2VkRD {
  background-color: hsla(0, 0%, 100%, 0.2);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 5.2rem;
  height: 0.9rem;
  padding: 0 0.3rem;
  margin-bottom: 0.2rem;
  border-radius: 0.45rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_inputBox__6n6TQ
  .register_inputGroup__2VkRD
  .register_inputIcon__2arpa.register_accountIcon__27e07 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAACXBIWXMAAAsTAAALEwEAmpwYAAACNUlEQVR42tWWLYvkQBCGDyIHGgKxY/MDxmVU3Mg1y5q4k/kD53a4XzA2dtyIhYVRMUPEyLFjAmPORcTFxTwXikCTrnzs3cEtWzGppvvt+uq36tvXFnwSjhSUNDSUFBxJ8P8O7IkLLWPScuHpz8Airv3RnJSYEIMhJCYl76+5En0MzOMAQEWKGd1hSKkAOOAtwRlysWzPanbfir1YmmPm4e5A9TFn2Iqdd8y0s7lsWGt7CAm1zazFgHzCcYld5cIRI2noUxQryAo4jGZWjmwdmzMAGgoKGgAyPMfxFtBB4grsh2sCV5Pg9fAJNZA5u/bAVZcxVMMosRO49dBFgdw5Ea7AKXUuQKpt5ll58qztIQUuwzfbdp9x3jHUY8kTe/xhucl5u0YC5M6xDbKmhRzY6DUSqx7FYQ14HgU8C6B2+mjVAlSFBUA16XKgqhUKq5ZAqA7ebD6d3N/UagiUVm0A/bBegF9O+P1uBV40CwDNHKAIbwIZW8cE7k2UEUDtsiaFdwAenLrvAcC7ulq5rJKi+MRKaV+OTooqG2VfRgu0lJy779FrBySuumzmC9uXLNf8wNgrOq0G7gSzha2fHkFPnTr3RuJ6w9dPb4YcyIAT3jizC+RZkcM0fbGRchFtHFIyvlP0NUWwnCQiM8J3IJ8hWKFyaIl6zvu52L1L7vIXSe63C01qWfAJbJNabKPLotvovzf6yDb65VHkdXEUebWjyH8YllTG1Ti3YqXGue2nDZzLI/GXlt8z5Efk//aSCgAAAABJRU5ErkJggg==);
  background-size: contain;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_inputBox__6n6TQ
  .register_inputGroup__2VkRD
  .register_inputMain__3Ur5m {
  -webkit-box-flex: 1;
  -ms-flex: 1 1;
  flex: 1 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column nowrap;
  flex-flow: column nowrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  position: relative;
  z-index: 2;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_inputBox__6n6TQ
  .register_inputGroup__2VkRD
  .register_inputMain__3Ur5m
  .register_input__1ukwi {
  caret-color: #fff !important;
  display: block;
  width: 100%;
  height: 0.4rem;
  line-height: 0.4rem;
  font-size: 0.24rem;
  color: #fff;
  padding: 0;
  text-align: justify;
  background-color: initial;
  border: none;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_inputBox__6n6TQ
  .register_inputGroup__2VkRD
  .register_operateBtn__12YnF.register_deleteBtn__2EnTW {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAAmCAQAAAACNCElAAAACXBIWXMAAAsTAAALEwEAmpwYAAABH0lEQVR42tWWu6rDMBBE0xhUuHBlUCNYUKPGjf7/2+a6C9q9OihxFU+Xx2Fn0Hj1+tVHu04V2a2iU/v3mKJLPch0fAba1NRBl9IqqqgvyFZmquqLatoQFVJiXYCbTEVqM5R9AOHstLk8ZulVJRdGirA2oia2a8g2WtU+/GWbWLeJj4POVv43yfr+VBlyG8b2uMqoW1cwCbii4lBe+/vrU51xAwp+S23MgBplLmTAoQOXKMCKmwthbLMut9f8+IxiF5mPhg1ZnYwbtkOcKiSVyawbnlEBZ1SnY1L0HJPhosd2NocKODXXTAdL7nUMxXGLMD15bV9LK0/t+ULhVcdK6xcDVgMUZcdlY1x6fnHxx9ieXqniCswy1VumrP31o88fKB+Q+PMIDnwAAAAASUVORK5CYII=);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50%;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_inputBox__6n6TQ
  .register_inputGroup__2VkRD
  .register_inputIcon__2arpa.register_accountIcon__27e07 {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAACXBIWXMAAAsTAAALEwEAmpwYAAACNUlEQVR42tWWLYvkQBCGDyIHGgKxY/MDxmVU3Mg1y5q4k/kD53a4XzA2dtyIhYVRMUPEyLFjAmPORcTFxTwXikCTrnzs3cEtWzGppvvt+uq36tvXFnwSjhSUNDSUFBxJ8P8O7IkLLWPScuHpz8Airv3RnJSYEIMhJCYl76+5En0MzOMAQEWKGd1hSKkAOOAtwRlysWzPanbfir1YmmPm4e5A9TFn2Iqdd8y0s7lsWGt7CAm1zazFgHzCcYld5cIRI2noUxQryAo4jGZWjmwdmzMAGgoKGgAyPMfxFtBB4grsh2sCV5Pg9fAJNZA5u/bAVZcxVMMosRO49dBFgdw5Ea7AKXUuQKpt5ll58qztIQUuwzfbdp9x3jHUY8kTe/xhucl5u0YC5M6xDbKmhRzY6DUSqx7FYQ14HgU8C6B2+mjVAlSFBUA16XKgqhUKq5ZAqA7ebD6d3N/UagiUVm0A/bBegF9O+P1uBV40CwDNHKAIbwIZW8cE7k2UEUDtsiaFdwAenLrvAcC7ulq5rJKi+MRKaV+OTooqG2VfRgu0lJy779FrBySuumzmC9uXLNf8wNgrOq0G7gSzha2fHkFPnTr3RuJ6w9dPb4YcyIAT3jizC+RZkcM0fbGRchFtHFIyvlP0NUWwnCQiM8J3IJ8hWKFyaIl6zvu52L1L7vIXSe63C01qWfAJbJNabKPLotvovzf6yDb65VHkdXEUebWjyH8YllTG1Ti3YqXGue2nDZzLI/GXlt8z5Efk//aSCgAAAABJRU5ErkJggg==);
  background-size: contain;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_inputBox__6n6TQ
  .register_inputGroup__2VkRD
  .register_inputIcon__2arpa {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 0.4rem;
  flex: 0 0 0.4rem;
  width: 0.4rem;
  height: 0.4rem;
  margin-right: 0.2rem;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_inputBox__6n6TQ
  .register_inputGroup__2VkRD
  .register_inputIcon__2arpa.register_passwordIcon__18fIi {
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAQAAAAm93DmAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2ElEQVR42tXWP2vbYBAG8ELopsEfIFm9ZhWeDNmyZA5oLBQ0Z/bcyZ8gk75CF02avJYsmfwJNBa8afn1xRTUtxdbqRIouUMgPbr3QfdHd/fpY4uFSqOzd0i612lUFvPI7nQGoiS0c/dvZKXd76Ot2tpSkXRprdYmFHbK15Fd2IJerTgRiFoPti6mo9ZisFGctStsDGgtztM9o3+dM1Z6PFucdrY9GlwFfOVr0tXfDrryjPaIR7FFH+jK46GjpLsyUPbYvuwAQzhwn7C9yjJpZW9wH84NWEXCHTY55tJPnWJMhS4hl7mVDXaxjOkVOerRwVXuYkIeQ8Z73OVghzp8da8NWKsPWI0uL5chaai8hH0L2IOngBXH84sRqNDGRI1RnRItqvGxQf0mwhpNHsF1ZvBZ6RaN2xe19DmzX2dRtMcyi8mTKXn6M+aW2I+PB7LXX0hXeUaPFtkncDhNuMHFRJNjEwiDyzMIg8tjUmYQhqSEsplBGMomFPYswhZV+PVmEIZfLzSHGYRjc4jtawZhbF+xwXrAYmI68hAa7KkR4Nqg9+OM9gbX4whQTgwpNxrfz2jjJgypiTE6IXGMvn3Ql2HQv+8qMn9ZKsKy9E7r3Oq/LZzTK/GHll8m3loGX3l4/QAAAABJRU5ErkJggg==);
  background-size: contain;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_ruleContainer__1h-YD {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 0.32rem;
  padding: 0 0.3rem;
  margin-top: 0.14rem;
  margin-bottom: 0.14rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 0.24rem;
  color: #fff;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_btnGroup__1vkHI {
  width: 100%;
  text-align: center;
}
.register_formContainer__3DhlE
  .register_registerContent__16A8K
  .register_form__1pmZ3
  .register_btnGroup__1vkHI
  button {
  width: 100%;
  height: 0.9rem;
  line-height: 0.9rem;
  border: none;
  border-radius: 0.45rem;
  font-size: 0.32rem;
  font-weight: 700;
  color: #fff;
  opacity: 0.5;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#62b3ff),
    to(#3d72fa)
  );
  background: linear-gradient(90deg, #62b3ff, #3d72fa);
  vertical-align: top;
}
</style>
