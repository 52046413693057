<template>
  <div class="foot">
    <div>
      <div style="height: 180px"></div>
      <img
        src="/static/image/bank_020021515.png"
        alt=""
        class="bancgs"
        @click="$router.back()"
      />
      <div class="topsf">
        <div class="tois">
          <img src="/static/image/tx.png" alt="" />
          <div class="dwd">
            <div class="tisaa">Hi,尊敬的会员用户</div>
            <div class="newsa">早上好，欢迎来到帮助中心</div>
          </div>
        </div>
        <div
          style="
            color: rgb(255, 255, 255);
            text-align: center;
            margin-top: 20px;
          "
        >
          若相关问题仍未解决，可咨询在线客服
        </div>
      </div>
      <div data-v-15276896="" class="bosfs">
        <div data-v-15276896="" class="hgsw" @click="toBetInfo(7)">
          <img
            data-v-15276896=""
            src="/static/image/1587555761884253.png"
            alt=""
            class="firsimg"
          />
          <span data-v-15276896="" class="tit">关于我们</span>
          <span data-v-15276896="" class="tisf"></span>
          <img
            data-v-15276896=""
            src="/static/image/icoArrowGrey@2x.png"
            alt=""
            class="rigiong"
          />
        </div>
        <div data-v-15276896="" class="hgsw" @click="toBetInfo(1)">
          <img
            data-v-15276896=""
            src="/static/image/1587555761884253.png"
            alt=""
            class="firsimg"
          />
          <span data-v-15276896="" class="tit">常见问题</span>
          <span data-v-15276896="" class="tisf"></span>
          <img
            data-v-15276896=""
            src="/static/style/icoArrowGrey@2x.abc43ab099390c4587d33290a76fc15e.png"
            alt=""
            class="rigiong"
          />
        </div>
        <div data-v-15276896="" class="hgsw" @click="toBetInfo(2)">
          <img
            data-v-15276896=""
            src="/static/image/1587555761884253.png"
            alt=""
            class="firsimg"
          />
          <span data-v-15276896="" class="tit">隐私政策</span>
          <span data-v-15276896="" class="tisf"></span>
          <img
            data-v-15276896=""
            src="/static/style/icoArrowGrey@2x.abc43ab099390c4587d33290a76fc15e.png"
            alt=""
            class="rigiong"
          />
        </div>
        <div data-v-15276896="" class="hgsw" @click="toBetInfo(3)">
          <img
            data-v-15276896=""
            src="/static/image/1587555761884253.png"
            alt=""
            class="firsimg"
          />
          <span data-v-15276896="" class="tit">免责说明</span>
          <span data-v-15276896="" class="tisf"></span>
          <img
            data-v-15276896=""
            src="/static/style/icoArrowGrey@2x.abc43ab099390c4587d33290a76fc15e.png"
            alt=""
            class="rigiong"
          />
        </div>
        <div data-v-15276896="" class="hgsw" @click="toBetInfo(4)">
          <img
            data-v-15276896=""
            src="/static/image/1587555761884253.png"
            alt=""
            class="firsimg"
          />
          <span data-v-15276896="" class="tit">联系我们</span>
          <span data-v-15276896="" class="tisf"></span>
          <img
            data-v-15276896=""
            src="/static/style/icoArrowGrey@2x.abc43ab099390c4587d33290a76fc15e.png"
            alt=""
            class="rigiong"
          />
        </div>
        <div data-v-15276896="" class="hgsw" @click="toBetInfo(5)">
          <img
            data-v-15276896=""
            src="/static/image/1587555761884253.png"
            alt=""
            class="firsimg"
          />
          <span data-v-15276896="" class="tit">代理加盟</span>
          <span data-v-15276896="" class="tisf"></span>
          <img
            data-v-15276896=""
            src="/static/style/icoArrowGrey@2x.abc43ab099390c4587d33290a76fc15e.png"
            alt=""
            class="rigiong"
          />
        </div>
        <div
          data-v-15276896=""
          class="hgsw"
          style="border: none"
          @click="toBetInfo(8)"
        >
          <img
            data-v-15276896=""
            src="/static/image/1587555761884253.png"
            alt=""
            class="firsimg"
          />
          <span data-v-15276896="" class="tit">博彩责任</span>
          <span data-v-15276896="" class="tisf"></span>
          <img
            data-v-15276896=""
            src="/static/style/icoArrowGrey@2x.abc43ab099390c4587d33290a76fc15e.png"
            alt=""
            class="rigiong"
          />
        </div>
      </div>
      <!-- 客服 -->
      <!-- <div
        style="
          margin-top: 0.48rem;
          text-align: center;
          color: rgb(108, 124, 157);
          padding-bottom: 0.6rem;
        "
      >
        没有找到解决办法？请联系<a
          style="color: rgb(89, 126, 247); font-weight: 600"
          @click="$parent.goNav('/kefu')"
          >人工客服</a
        >解决
      </div> -->
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    toBetInfo(type) {
      console.log(type, "type");
      this.$parent.goNav(`/boutBallBetInfo?type=${type}`);
    },
  },
};
</script>
<style lang="scss" scoped>
* {
  font-size: 12px;
}
.topsf {
  background: url("/static/image/welcome-bg.png") bottom no-repeat;
  background-size: 100% 100%;
  height: 180px;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
}
.topsf .tois {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  margin: 0 auto;
  width: 90%;
  color: #fff;
}
.topsf .tois img {
  width: 50px;
  margin-right: 15px;
}
img {
  overflow-clip-margin: content-box;
  overflow: clip;
}
.topsf .tois {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 20px;
  border-bottom: 1px solid #fff;
  margin: 0 auto;
  width: 90%;
  color: #fff;
}
.topsf .tois .tisaa {
  font-size: 16px;
  font-weight: 700;
}
.topsf .tois .newsa {
  margin-top: 6px;
  font-size: 10px;
}
.bosfs {
  border-radius: 15px;
  padding: 4px 12px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 90%;
  margin: 15px auto 0;
  background-size: 100% 100%;
  -webkit-box-shadow: 0 0.04rem 0.2rem 0 rgba(93, 114, 162, 0.11);
  box-shadow: 0 0.04rem 0.2rem 0 rgba(93, 114, 162, 0.11);
}
.bosfs .hgsw {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #f5f0f0;
}
.bosfs .hgsw .firsimg {
  width: 24px;
}
.bosfs .hgsw .tit {
  color: #4e6693;
  margin-left: 10px;
}
.bosfs .hgsw .tisf {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 12px;
  color: #a2aec8;
  text-align: right;
}
.bosfs .hgsw .rigiong {
  width: 6px;
}
</style>
