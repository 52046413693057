<template>
  <div class="kefu"></div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
    };
  },
  created() {
    this.geturl();
  },
  methods: {
    async geturl() {
      let res = await this.$apiFun.post("/api/getservicerurl");
      // window.open(res.data.url, "_self");
    },
  },
};
</script>
<style lang="scss" scoped>
.foot {
  width: 100%;
  height: 50rpx;
  border: 1rpx solid red;
  /* position: relative;
  bottom: 0; */
}
.cont {
  width: 100%;
  height: 50rpx;
  border: 1rpx solid red;
}
</style>
