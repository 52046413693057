<template>
  <div class="foot">
    <div>
      <div
        style="width: 100%; min-height: 100vh; background: rgb(241, 241, 241)"
      >
        <div
          class="van-nav-bar van-hairline--bottom"
          style="
            position: fixed;
            top: 0px;
            left: 0px;
            width: 100%;
            background-color: rgb(237, 242, 253);
          "
        >
          <div class="van-nav-bar__content">
            <van-nav-bar
              style="
                position: fixed;
                top: 0;
                left: 0;
                width: 100%;
                background-color: #edf2fd;
              "
              title="个人资料"
              left-arrow
              @click-left="$router.back()"
            />
          </div>
        </div>
        <div style="height: 46px"></div>
        <div class="tops">
          <img src="/static/image/safety.png" alt="" />
          <div class="tes">完善账户信息，更安全</div>
        </div>
        <div class="boxst">
          <div class="hgs" @click="$parent.goNav('/userInfo')">
            <div class="lfs">
              <div class="topas">完善个人资料</div>
              <div class="tisg">资料更完整，我们的服务更加周到</div>
            </div>
            <div class="rigs">
              去完善
              <img src="/static/image/right.png" alt="" />
            </div>
          </div>
          <!-- <div  class="hgs">
            <div data-v-5575261c="" class="lfs">
              <div data-v-5575261c="" class="topas">卡片管理</div>
              <div data-v-5575261c="" class="tisg">
                如需提现，请绑定银行卡或虚拟币地址
              </div>
            </div>
            <div data-v-5575261c="" class="rigs">
              <img data-v-5575261c="" src="/static/image/right.png" alt="" />
            </div>
          </div> -->
          <div class="hgs" @click="$parent.goNav('/password?type=1')">
            <div class="lfs">
              <div class="topas">登录密码管理</div>
              <div class="tisg">定期修改登录密码，有利账户安全</div>
            </div>
            <div class="rigs">
              <img src="/static/image/right.png" alt="" />
            </div>
          </div>
          <!-- <div data-v-5575261c="" class="hgs">
            <div data-v-5575261c="" class="lfs">
              <div data-v-5575261c="" class="topas">取款密码管理</div>
              <div data-v-5575261c="" class="tisg">
                定期修改登录密码，有利账户安全
              </div>
            </div>
            <div data-v-5575261c="" class="rigs">
              <img data-v-5575261c="" src="/static/image/right.png" alt="" />
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.tops {
  background: url("/static/image/safety_back.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 5rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px 0;
  min-height: 200px;
}
.tops img {
  width: 3rem;
  display: block;
  margin: 0 auto;
}
.tops .tes {
  color: #fff;
  font-size: 0.3rem;
  text-align: center;
  padding-top: 15px;
}
.boxst {
  width: 90%;
  background: #fff;
  border-radius: 20px;
  position: relative;
  margin: -10px auto 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 20px;
}
.boxst .hgs {
  border-bottom: 1px solid #f1f1f1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 1.3rem;
  min-height: 50px;
}
.boxst .hgs .lfs {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
}
.boxst .hgs .rigs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #999;
  font-size: 0.3rem;
}
.boxst .hgs .lfs .topas {
  font-size: 0.3rem;
}
.boxst .hgs .lfs .tisg {
  margin-top: 4px;
  color: #999;
  font-size: 0.3rem;
}
.boxst .hgs .rigs img {
  width: 0.34rem;
  margin-left: 4px;
}
</style>
